$(function(){
  $('tr[data-href]').addClass('clickable').on('click', function (e) {
    if (!$(e.target).is('a')) {
      window.location = $(e.target).closest('tr').data('href');
    };
  });
  $('div[data-href]').addClass('clickable').on('click', function (e) {
    if (!$(e.target).is('a')) {
      window.location = $(this).data('href');
    };
  });
});
